import React, { ReactNode, useContext, useState } from 'react';
import {
    AppBar,
    createStyles,
    Grid,
    IconButton,
    Theme,
    Toolbar,
    Typography,
    useScrollTrigger,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import TopProgress from './TopProgress';
import { runInAction } from 'mobx';
import MenuIcon from 'mdi-material-ui/Menu';
import { Log } from '../../log';
import { makeStyles } from '@material-ui/core/styles';
import { StoreContext } from '../../App';
import { LogoutIcon, FullscreenIcon, FullscreenExitIcon, QuestionMarkIcon } from './SvgIcons';
import AlgoliaAutocomplete from './algolia/AlgoliaAutocomplete';
import { IndexSearchConfig } from './algolia/algoliaSearchIndexConfigurations';
import { serviceDeskUrl } from '../../environmentConstants';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(({ zIndex, transitions }: Theme) =>
    createStyles({
        appBar: {
            zIndex: zIndex.drawer + 1,
            transition: transitions.create(['all'], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),
            backgroundColor: 'transparent',
            position: 'fixed',
            top: 0,
            left: 270,
            right: 0,
            width: 'auto',
        },
        appBarFixed: {
            zIndex: zIndex.drawer + 1,
            transition: transitions.create(['all'], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),
            background: '#ffffff',
            width: 'inherit',
            left: 270,
            right: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        },
        leftContent: {
            flexGrow: 1,
        },
        grid: {
            display: 'flex',
        },
        title: {
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                marginRight: '0.3rem',
                fill: '#398BF7',
            },
        },
    })
);

interface AdminToolbarProps {
    title: string;
    toolbarItems?: ReactNode;
    inProgress?: boolean;
    indexSearchConfigs?: IndexSearchConfig[];
    handleSelectItem?: (id: string, index: any) => void;
    window?: () => Window;
}

const ScreenToolbar = observer(
    (
        { title, toolbarItems, inProgress, indexSearchConfigs, handleSelectItem, window }: AdminToolbarProps,
        props: any
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const { authStore, uiStore } = useContext(StoreContext);
        const [isFullscreen, setIsFullscreen] = useState(false);

        const toggleFullScreen = () => {
            if (!(document as any).fullscreenElement) {
                Log.fullscreen(true);
                document.documentElement.requestFullscreen();
                setIsFullscreen(true);
            } else {
                if (document.exitFullscreen) {
                    Log.fullscreen(false);
                    document.exitFullscreen();
                    setIsFullscreen(false);
                }
            }
        };

        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 1,
        });

        const isSearchBoxVisible = useScrollTrigger({
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 70,
        });

        const handleDrawerOpen = () => {
            runInAction(() => (uiStore!.isMobileMenuOpen = !uiStore!.isMobileMenuOpen));
            runInAction(() => (uiStore!.isDrawerOpen = !uiStore!.isDrawerOpen));
        };

        const handleLogout = () => {
            runInAction(() => authStore!.logout());
        };

        return (
            <AppBar
                className={trigger ? classes.appBarFixed : classes.appBar}
                color="inherit"
                elevation={0}
                style={
                    (!uiStore!.isDrawerOpen && uiStore!.isLargeDesktop) ||
                    (!uiStore!.isDrawerOpen && uiStore!.isDesktop)
                        ? { left: 72 }
                        : uiStore!.isTablet
                        ? { left: 0 }
                        : undefined
                }
            >
                <TopProgress inProgress={inProgress!} />
                <Toolbar>
                    <Grid container className={classes.leftContent} alignItems={'center'}>
                        <Grid container item sm={3} md={4} style={{ flexWrap: 'nowrap' }}>
                            <IconButton onClick={handleDrawerOpen}>
                                <MenuIcon style={{ fill: '#398BF7' }} />
                            </IconButton>
                            {indexSearchConfigs && isSearchBoxVisible && (
                                <AlgoliaAutocomplete
                                    onHitIdSelected={handleSelectItem}
                                    selectHitId={true}
                                    indexSearchConfigs={indexSearchConfigs!}
                                    placeholder={t('search')}
                                />
                            )}
                        </Grid>
                        <Grid container item sm={6} md={4} justify="center">
                            <Typography component="h1" variant="h6" noWrap className={classes.title}>
                                {toolbarItems} {title}
                            </Typography>
                        </Grid>
                        <Grid container item sm={3} md={4} justify="flex-end" wrap={'nowrap'}>
                            <Tooltip title={t('help') as any}>
                                <Link href={serviceDeskUrl} target={'_blank'}>
                                    <IconButton color={'primary'}>
                                        <QuestionMarkIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <Tooltip title={isFullscreen ? (t('fullscreen-exit') as any) : (t('fullscreen') as any)}>
                                <IconButton color={'primary'} onClick={toggleFullScreen}>
                                    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('logout') as any}>
                                <IconButton color={'primary'} onClick={handleLogout}>
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
                {props.children}
            </AppBar>
        );
    }
);

export default ScreenToolbar;
