import { RootStore } from '../../../stores/rootStore';
import { action, observable, runInAction, reaction, computed } from 'mobx';
import { SortDirection } from '../../../common/models/sortDirection';
import { AppError } from '../../../common/models/appError';
import { CustomerFilter } from '../models/customerFilter';
import { SortSetting } from '../../../common/models/sortSetting';
import { CustomerDto } from '../../../api/dtos/generated/dtos.generated';
import Firebase from '../../../api/firebase';
import { Customer } from '../models/customer';
import { Log } from '../../../log';
import i18n from 'i18next';
import { createPdfDocDefinition, buildTableBody, openOrDownloadPdf } from '../../../common/helpers/pdfHelper';
import { format, startOfDay, subDays } from 'date-fns';
import { groupBy, orderBy } from 'lodash';
import firebase from 'firebase';

class CustomersStore {
    public static storeName: string = 'customersStore';

    public store: RootStore;
    @observable private customers: Customer[] = [];
    @observable public filter: CustomerFilter = { searchTerm: '' };
    @observable public sortSetting: SortSetting = {
        fieldName: 'firstName',
        direction: SortDirection.Asc,
    };
    @observable public isLoading: boolean = false;
    @observable public initialLoadDone: boolean = false;
    @observable error?: AppError = undefined;
    @observable public inAction: boolean = false;
    @observable public isExportInProgress = false;

    private unsubsribeCustomerChangesFunc?: () => void;
    private isFirstSnapShotHandled = false;

    constructor(store: RootStore) {
        this.store = store;

        reaction(
            () => this.store.authStore.isAuthenticated,
            () => this.subscribeCustomerChanges()
        );
    }

    @action
    public async subscribeCustomerChanges() {
        runInAction(() => (this.isLoading = true));
        const query = Firebase.firestore
            .collection('tenants')
            .doc(this.store.authStore.tenantId)
            .collection('customers');

        this.unsubsribeCustomerChangesFunc = query.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot) => {
            try {
                runInAction(() => (this.isLoading = true));

                if (this.isFirstSnapShotHandled) {
                    snapshot.docChanges().forEach((change: firebase.firestore.DocumentChange) => {
                        if (change.type === 'added') {
                            this.addCustomer(change.doc.data() as CustomerDto);
                        }
                        if (change.type === 'modified') {
                            this.upsertCustomer(change.doc.data() as CustomerDto);
                        }
                        if (change.type === 'removed') {
                            this.removeCustomer(change.doc.data().id);
                        }
                    });
                } else {
                    const customers: Customer[] = [];
                    for (const customerSnapshot of snapshot.docs) {
                        const dto = customerSnapshot.data() as CustomerDto;
                        const customerModel = Customer.createFromDto(dto);
                        customers.push(customerModel);
                    }
                    this.customers = customers;
                    this.isFirstSnapShotHandled = true;
                    this.initialLoadDone = true;
                }
            } finally {
                runInAction(() => (this.isLoading = false));
            }
        });
    }

    @action
    private async addCustomer(dto: CustomerDto) {
        const model = Customer.createFromDto(dto);
        this.customers.push(model);
    }

    @action
    private async upsertCustomer(dto: CustomerDto) {
        const customer = this.customers.find((x) => x.id === dto.id);
        if (customer) {
            this.removeCustomer(dto.id);
        }

        this.addCustomer(dto);
    }

    @action
    private async removeCustomer(id: string) {
        const index = this.customers.findIndex((x) => x.id === id);
        if (index > -1) {
            this.customers.splice(index, 1);
        }
    }

    @action
    setSortSetting(sortSetting: SortSetting) {
        this.sortSetting = sortSetting;
    }

    @action
    public createPdf = async () => {
        this.isExportInProgress = true;
        this.error = undefined;

        Log.export('customerList', 'pdf');

        const pdfMake = (await import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake')).default;
        const pdfFonts = (await import(/* webpackChunkName: "pdfFonts" */ 'pdfmake/build/vfs_fonts')).default;

        const { vfs } = pdfFonts.pdfMake;
        pdfMake.vfs = vfs;

        const tenant = this.store.tenantStore.tenant;

        const tableHeadColumns: { field: string; header: string }[] = [
            { field: 'companyName', header: i18n.t('company-name') },
            { field: 'firstName', header: i18n.t('firstName') },
            { field: 'lastName', header: i18n.t('lastName') },
            { field: 'email', header: i18n.t('email') },
            { field: 'phone', header: i18n.t('phone') },
        ];

        const tableWidths = ['auto', 'auto', 'auto', 'auto', '*'];

        const pdfTitle = i18n.t('customers');

        if (tenant) {
            const docDefinition = createPdfDocDefinition(
                tableWidths,
                pdfTitle,
                buildTableBody(this.customers, tableHeadColumns),
                tenant.toDto()
            );

            const pdf = pdfMake.createPdf(docDefinition as any);
            openOrDownloadPdf(pdf, `${pdfTitle}_${format(new Date(), 'd.M.yyyy')}.pdf`);

            runInAction(() => {
                this.isExportInProgress = false;
            });
        }
    };

    @computed
    public get sortedCustomers() {
        return this.customers.sort((a: Customer, b: Customer) => {
            const aValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), a);
            const bValue = this.sortSetting.fieldName.split('.').reduce((x: any, y) => x[y].toLowerCase(), b);

            return this.sortSetting.direction === SortDirection.Desc
                ? aValue < bValue
                    ? 1
                    : -1
                : aValue > bValue
                ? 1
                : -1;
        });
    }

    @computed
    public get customersPerDayCount(): any {
        const ordered = orderBy(this.customers, ['createdAt'], ['asc']);
        const mapped = ordered.map((x: any) => {
            return { date: startOfDay(x.createdAt ? x.createdAt : new Date(8640000000000000)) };
        });
        const grouped = groupBy(mapped, 'date') as any;

        const result = [];
        if (ordered.length > 0 && ordered[0].createdAt) {
            result.push({ date: subDays(ordered[0].createdAt, 1), count: 0 });
        }

        let sumCount = 0;
        for (const [key, value] of Object.entries(grouped)) {
            sumCount += (value as any).length;
            result.push({ date: key, count: sumCount });
        }

        return result;
    }

    @computed
    public get inProgress() {
        return this.isLoading || this.inAction;
    }

    @computed
    public get hasCustomers() {
        return this.customers.length > 0;
    }

    @computed
    public get customersCount() {
        return this.customers.length;
    }
}

export default CustomersStore;
